<template>
  <div
    class="py-10 border-b last:border-none border-gray-200 even:bg-gray-50 hover:static-gradient-transparent px-4 lg:px-10 flex flex-col lg:flex-row gap-6 md:gap-0 lg:items-center space-x-6"
  >
    <div class="flex flex-grow items-center gap-6">
      <div>
        <img v-if="!isMobile" class="w-32 min-w-16" :src="productImage || ''" />
      </div>
      <div class="flex-auto flex flex-col">
        <div class="font-medium text-gray-900 mr-2">
          <span
            v-if="(isPublisher || isAdmin || isSoftAdmin) && !product?.approved"
            class="relative inline-flex h-3 w-3 mr-1"
          >
            <span
              class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 bg-yellow-500"
            ></span>
            <span
              class="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"
            ></span>
          </span>
          <strong class="text-xl xl:text-lg">{{ product?.name }}</strong>
          <div class="flex gap-3 items-center">
            <span
              v-if="product?.website && product.type === 'newspaper'"
              class="text-red-500"
              >{{ t(product.type) }}</span
            >
            <span
              v-else-if="product?.website && product.type !== 'newspaper'"
              class="text-primary-600"
              >{{ t(product.type) }}</span
            >
            <div class="flex gap-1 mx-1">
              <a
                v-for="i of types"
                :key="i"
                target="_BLANK"
                :href="!((product as any)[i][0]).match(/http/i) ? `https://${((product as any)[i][0])}`: ((product as any)[i][0])"
              >
                <img :src="`/assets/icons/${i}.svg`" width="16" height="16" />
              </a>
            </div>
          </div>
          <p
            v-if="product?.description !== ''"
            class="mt-8 lg:mt-2 text-sm text-gray-600 max-w-2xl"
            v-html="product?.description"
          ></p>
        </div>
        <div v-if="!isPublisher || isAdmin || isSoftAdmin">
          <dl
            v-if="product?.type !== 'social' && product?.website"
            class="flex text-sm gap-2 my-2"
          >
            <div
              v-for="metric of [
                'da',
                'dr',
                ...(product.country === 'it' ? ['za'] : []),
              ]"
              :key="metric"
              class="flex"
            >
              <dt class="font-medium text-gray-900">{{ t(metric) }}</dt>
              <dd class="ml-2 text-gray-700">
                {{
                  (product?.metrics ?? {})[
                    metric as keyof typeof product.metrics
                  ] ?? 0
                }}
              </dd>
            </div>
          </dl>
          <dl
            v-if="product?.type !== 'social' && product?.website"
            class="flex text-sm gap-2 my-2"
          >
            <strong>{{ t('backlinks') }}</strong>
            <div v-for="metric of ['total']" :key="metric" class="flex">
              <dt class="font-medium text-gray-900">{{ t(metric) }}</dt>
              <dd class="ml-2 text-gray-700">
                {{
                  (product?.metrics.backlinks ?? {})[
                    metric as keyof typeof product.metrics.backlinks
                  ] ?? 0
                }}
              </dd>
            </div>
          </dl>
          <dl
            v-if="product?.type !== 'social' && product?.website"
            class="flex text-sm gap-2 my-2"
          >
            <strong>{{ t('google-news') }}</strong>
            <div class="flex">
              <dd class="ml-2 text-gray-700">
                {{ t(product?.google_news ? 'yes' : 'no') }}
              </dd>
            </div>
          </dl>
          <div v-if="isAdmin || isSoftAdmin" class="flex mt-1 text-sm">
            <dt class="font-medium text-gray-900">{{ t('owner') }}:</dt>
            <dd class="ml-2 text-gray-700">
              <a
                class="underline"
                :href="'/app/admin/users/' + product?.owner"
                target="_BLANK"
                >{{ owner }}</a
              >
            </dd>
          </div>
          <!--<dl v-if="product?.website && product.metrics?.wr" class="flex text-sm gap-2 my-2">
            <img src="/assets/icons/wr.png" class="h-5 w-5 cursor-pointer" @click="openWRInfoPopup()" /> <strong class="cursor-pointer" @click="openWRInfoPopup()">Wolf Rank</strong>
            <div class="flex">
              <dd class="ml-2 text-gray-700">
                {{ product.metrics.wr }}
              </dd>
            </div>
          </dl>-->
        </div>
        <div
          v-if="!isAdmin && !isSoftAdmin && isPublisher"
          class="mt-6 flex-1 flex items-end"
        >
          <dl
            class="flex text-sm divide-x divide-gray-200 space-x-4 sm:space-x-6"
          >
            <div class="flex">
              <dt class="font-medium text-gray-900">Total orders</dt>
              <dd class="ml-2 text-gray-700">{{ stats.orders }}</dd>
            </div>
            <div class="pl-4 flex sm:pl-6">
              <dt class="font-medium text-gray-900">Total revenue</dt>
              <dd class="ml-2 text-gray-700">
                &euro; {{ stats.revenue.format() }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div v-if="product" class="flex text-xl font-bold whitespace-nowrap">
        <template
          v-if="
            product.promo &&
            product.promo.endDate.seconds * 1000 > new Date().getTime()
          "
        >
          <s>&euro; {{ product.price.format() }}</s
          >&nbsp;
          <span class="text-primary-500 font-bold"
            >&euro;
            {{
              (product.price * ((100 - product.promo.discount) / 100)).format()
            }}</span
          >
        </template>
        <template v-else>
          <span class="text-primary-500 font-bold"
            >&euro; {{ product.price.format() }}</span
          ></template
        >
      </div>
    </div>
    <div class="mt-4 lg:mt-0 flex items-center justify-evenly gap-2">
      <template v-if="product && !product.canceled">
        <button
          v-if="
            (isAdmin || isSoftAdmin) &&
            !product?.approved &&
            (product?.type === 'social' || product?.updates)
          "
          class="text-emerald-500"
          @click="approve()"
        >
          <CheckCircleIcon class="w-8 h-8" />
        </button>
        <button
          v-if="updatingWebsite"
          class="bg-primary-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        >
          <svg
            class="animate-spin h-4 w-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </button>
        <button
          v-if="!updatingWebsite && (isAdmin || isSoftAdmin)"
          class="bg-red-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
          @click="updateScreenshot()"
        >
          <DesktopComputerIcon class="h-4 w-4" />
        </button>
        <button
          v-if="!updatingWebsite && (isAdmin || isSoftAdmin)"
          class="bg-blue-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
          @click="updateWebsite()"
        >
          <CloudUploadIcon class="h-4 w-4" />
        </button>
        <Tooltip content="tooltip-product-view">
          <button
            v-if="isAdmin || isSoftAdmin"
            class="block gradient h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
            @click="openPopup"
          >
            <EyeIcon class="w-4 h-4" />
          </button>
          <a
            v-else-if="product?.approved"
            target="_BLANK"
            class="block gradient h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
            :href="
              '/app/' +
              (route.path.includes('advertiser') ? 'advertiser' : 'publisher') +
              '/products/view/' +
              product?.uid
            "
          >
            <EyeIcon class="w-4 h-4" />
          </a>
        </Tooltip>
        <button
          v-if="isAdmin || isSoftAdmin"
          class="bg-purple-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
          @click="openMetricsPopup"
        >
          <TrendingUpIcon class="h-4 w-4" />
        </button>
        <template v-if="isAdmin || isSoftAdmin || isPublisher">
          <Tooltip v-if="isPublisher" content="tooltip-product-featured">
            <button
              class="bg-emerald-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
              @click="openFeaturedPopup"
            >
              <CurrencyDollarIcon class="h-4 w-4" />
            </button>
          </Tooltip>
          <Tooltip content="tooltip-product-pencil">
            <a
              target="_BLANK"
              :href="
                isAdmin
                  ? `/app/admin/products/${product?.uid}/edit`
                  : isSoftAdmin
                  ? `/app/soft-admin/products/${product?.uid}/edit`
                  : `/app/publisher/products/${product?.uid}/edit`
              "
              class="block bg-primary-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
            >
              <PencilIcon class="h-4 w-4" />
            </a>
          </Tooltip>
          <Tooltip
            v-if="isAdmin || isSoftAdmin"
            content="tooltip-product-reject"
          >
            <button
              class="bg-orange-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
              @click="mediaReject"
            >
              <ExclamationCircleIcon class="h-4 w-4" />
            </button>
          </Tooltip>
          <Tooltip v-if="isAdmin" content="tooltip-product-delete">
            <button
              class="bg-red-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
              @click="mediaDelete"
            >
              <XCircleIcon class="h-4 w-4" />
            </button>
          </Tooltip>
          <!--<Tooltip content="tooltip-product-remove">
            <button
              class="block text-red-600 w-1/3 text-center lg:w-auto"
              @click="remove"
            >
              <XCircleIcon class="h-8 w-8" />
            </button>
          </Tooltip>-->
        </template>
        <template v-else>
          <Tooltip content="tooltip-product-star">
            <button
              class="block w-1/3 text-center lg:w-auto"
              :class="isPreferred ? 'text-yellow-500' : 'text-gray-200'"
              @click="preferred"
            >
              <StarIcon class="relative h-8 w-8" />
            </button>
          </Tooltip>
          <button
            class="flex items-center btn bg-red-500 text-white"
            @click.prevent="addToCart(product as Product)"
          >
            <PlusIcon class="h-5 w-6" />
            {{ t('add-to-cart') }}
          </button>
        </template>
      </template>
      <Tooltip
        v-if="isAdmin && product && product.canceled"
        content="tooltip-product-restore"
      >
        <button
          class="bg-gray-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
          @click="mediaRestore"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
        </button>
      </Tooltip>
      <Tooltip v-if="product?.uid" content="Find orders">
        <a
          target="_BLANK"
          :href="`/app/admin/orders?productUid=${product?.uid}`"
          class="block bg-primary-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        >
          <ClipboardListIcon class="w-4 h-4" />
        </a>
      </Tooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CurrencyDollarIcon, DesktopComputerIcon } from '@heroicons/vue/outline'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Product from '../models/product'
import { useFirebase } from '../plugins/firebase'
import { ref as StorageRef, getDownloadURL } from '@firebase/storage'
import User from '../models/user'
import { State } from '../store'
import {
  XCircleIcon,
  ExclamationCircleIcon,
  PencilIcon,
  PlusIcon,
  EyeIcon,
  CheckCircleIcon,
  StarIcon,
  CloudUploadIcon,
  TrendingUpIcon,
  ClipboardListIcon,
} from '@heroicons/vue/solid/'
import ProductPopup from './popups/ProductPopup.vue'
import ProductMetricsPopup from './popups/ProductMetricsPopup.vue'
import { useI18n } from 'vue-i18n'
import Loader from './Loader.vue'
import Tooltip from './Tooltip.vue'
import Order from '../models/order'
import ProductPublisherFeatured from './popups/ProductPublisherFeatured.vue'

const route = useRoute()
const { t } = useI18n()
const props = defineProps<{
  product?: Product
  users?: User[]
  promo?: boolean
}>()
const emit = defineEmits(['update'])

const isMobile = ref(window.innerWidth < 1024)
const updateMobile = () => (isMobile.value = window.innerWidth < 1024)
onMounted(() => window.addEventListener('resize', updateMobile))
onUnmounted(() => window.removeEventListener('resize', updateMobile))
const stats = ref<{ orders: number; revenue: number }>({
  orders: 0,
  revenue: 0,
})

Order.viewable().then((orders) => {
  if (!orders) return
  const _stats = { ...stats.value }
  for (const order of orders) {
    if (
      order.product !== props.product?.uid ||
      order.status === 'canceled' ||
      order.status === 'refunded'
    )
      continue
    _stats.orders++
    _stats.revenue =
      _stats.revenue + (order.price - order.price * order.owner_fee)
  }
  stats.value = _stats
})

const types = [
  'website',
  'facebook',
  'instagram',
  'youtube',
  'tiktok',
  'reddit',
  'telegram',
].filter(
  (type) => (props?.product as unknown as Record<string, []>)[type].length,
)

const productImage = ref<string>('/assets/product_placeholder.jpg')
const store = useStore<State>()

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')
const isPublisher = store.state.AccountType.type === 'publisher'
const isPreferred = ref<boolean>(false)
const updatingWebsite = ref<boolean>(false)

const user = ref<User>()
User.getCurrent().then((r) => {
  user.value = r
  isPreferred.value = (r.meta?.favorites ?? []).includes(
    props.product?.uid as string,
  ) as boolean
})

//const router = useRouter()
const addToCart = (product: Product) => {
  store.commit('cart/add', product)
  store.commit('snackbar/open', 'media-added-to-cart')
}

const remove = async () => {
  if (window.confirm('confirm-product-delete')) {
    store.commit('popup/open', { popup: Loader })
    await props.product?.delete()
    emit('update')
    store.commit('popup/close')
  }
}

const preferred = async () => {
  store.commit('popup/open', { popup: Loader })
  await user.value?.addFavorite(props.product?.uid as string).then(() => {
    isPreferred.value = !isPreferred.value
  })
  store.commit('popup/close')
}

const openPopup = () =>
  store.commit('popup/open', {
    popup: ProductPopup,
    product: props.product,
    productImage: productImage.value,
  })

const openMetricsPopup = () =>
  store.commit('popup/open', {
    popup: ProductMetricsPopup,
    product: props.product,
  })

const firebase = useFirebase()
const approve = async () => {
  try {
    store.commit('popup/open', { popup: Loader })
    await firebase.function('mediaApprove', {
      product: props.product?.uid,
      approved: true,
    })
    emit('update')
    store.commit('popup/close')
  } catch (e) {
    console.error(e)
    alert('fai screenshot console')
  }
}

const mediaReject = async () => {
  const reason = prompt(
    'Enter reason for rejection, the user will be notified through email',
  )
  if (!reason) {
    alert('You must enter a reason for rejection')
    return
  }
  try {
    store.commit('popup/open', { popup: Loader })
    await firebase.function('mediaApprove', {
      product: props.product?.uid,
      approved: false,
      reason,
    })
    emit('update')
    store.commit('popup/close')
  } catch (e) {
    console.error(e)
    alert('fai screenshot console')
  }
}

const mediaDelete = async () => {
  try {
    store.commit('popup/open', { popup: Loader })
    await firebase.function('mediaDelete', {
      product: props.product?.uid,
    })
    emit('update')
    store.commit('popup/close')
  } catch (e) {
    console.error(e)
    alert('fai screenshot console')
  }
}

const mediaRestore = async () => {
  try {
    store.commit('popup/open', { popup: Loader })
    await firebase.function('mediaDelete', {
      product: props.product?.uid,
      restore: true,
    })
    emit('update')
    store.commit('popup/close')
  } catch (e) {
    console.error(e)
    alert('fai screenshot console')
  }
}

const updateWebsite = async () => {
  updatingWebsite.value = true //store.commit('popup/open', { popup: Loader })
  await firebase.function('updateWebsite', { product: props.product?.uid })
  emit('update')
  updatingWebsite.value = false //store.commit('popup/close')
}

const updateScreenshot = async () => {
  updatingWebsite.value = true //store.commit('popup/open', { popup: Loader })
  await firebase.function('updateScreenshot', { product: props.product?.uid })
  emit('update')
  updatingWebsite.value = false //store.commit('popup/close')
}

if (props.product?.screenshot) {
  getDownloadURL(
    StorageRef(
      firebase.getStorage(),
      decodeURIComponent(props.product.screenshot),
    ),
  )
    .then((r) => {
      productImage.value = r
    })
    .catch((e) => {
      console.log(e)
    })
}

/*const openWRInfoPopup = () => {
  store.commit('popup/open', { popup: InfoVue, info: 'wolf-rank' })
}*/

const owner = computed(() => {
  const user = props.users?.find((i) => i.uid === props.product?.owner)
  if (user) return user.displayName + ' - ' + user.email
  return props.product?.owner
})

const openFeaturedPopup = () => {
  store.commit('popup/open', {
    popup: ProductPublisherFeatured,
    product: props.product,
  })
}
</script>
